import bind from 'bind-decorator';
import Component from './component';
import smoothscroll from 'smoothscroll-polyfill';

class ScrollToAnchor extends Component {
  initialize() {
    smoothscroll.polyfill();
    this.$element.on('click', this.onClick);
  }

  destroy() {
    this.$element.off('click', this.onClick);
  }

  @bind
  private onClick(event: JQuery.ClickEvent) {
    event.preventDefault();

    const hash = this.$element.attr('href');
    if (!hash) return;

    const elementToScrollTo = document.getElementById(hash.substring(1));
    if (!elementToScrollTo) return;

    const offsetPosition = elementToScrollTo.offsetTop;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}

export default ScrollToAnchor;
