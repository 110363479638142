import {sticky} from './utils';
import Component from './component';

const OFFSET = 100;

class BeerBackButton extends Component {
  private destroySticky = sticky(this.element, ({y}) => y <= OFFSET);

  destroy() {
    this.destroySticky();
  }
}

export default BeerBackButton;
