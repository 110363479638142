import bind from 'bind-decorator';
import ImagesCarousel from './images-carousel';
import SlidesNavigation from './mixins/slides-navigation';

class BeerCategoriesCarousel extends ImagesCarousel {
  protected $previousButton = this.$element.find('[data-carousel-previous]');
  protected $nextButton = this.$element.find('[data-carousel-next]');

  $currentSlide = this.$element.find('[data-current-slide]');

  private _currentSlideSubscription = this.currentSlide.subscribe(
    this.renderCount
  );

  initialize() {
    super.initialize();
    this.$previousButton.on('click', this.goToPreviousSlide);
    this.$nextButton.on('click', this.goToNextSlide);
  }

  destroy() {
    super.destroy();
    this.$previousButton.off('click');
    this.$nextButton.off('click');
    this._currentSlideSubscription.unsubscribe();
  }

  @bind
  renderCount() {
    const currentSlide = this.currentSlideIndex + 1;
    this.$currentSlide.html(currentSlide.toString().padStart(2, '0'));
  }
}

export default SlidesNavigation(
  BeerCategoriesCarousel,
  'beer-categories-carousel__list-item--active'
);
