import Component from './component';
import {sticky} from './utils';

const OFFSET = 73;
const RESTAURANT_PAGE_OFFSET = 145;
const RESTAURANT_PAGE_CLASS_NAME
  = 'our-beers__category-selector--restaurant-page';

class BeersQuickNav extends Component {
  private isRestaurantPage = this.element.classList.contains(
    RESTAURANT_PAGE_CLASS_NAME
  );

  private offset = this.isRestaurantPage ? RESTAURANT_PAGE_OFFSET : OFFSET;

  private destroySticky = sticky(this.element, ({y}) => y <= this.offset);

  destroy() {
    this.destroySticky();
  }
}

export default BeersQuickNav;
