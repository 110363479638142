import type {Observable} from 'rxjs';
import type Component from '../component';

type Constructor<T = {}> = new (...args: any[]) => T;

interface Navigatable extends Component {
  readonly currentSlideIndex: number;
  currentSlide: Observable<number>;
  goToSlide(slideIndex: number): void;
}

const SlidesNavigation = <Base extends Constructor<Navigatable>>(
  Base: Base,
  activeClass: string
) => {
  return class SlidesNavigation extends Base {
    protected $slidesNavigation = this.$element.find(
      '[data-slides-navigation]'
    );
    protected $slidesNavigationButtons = this.$slidesNavigation.children();

    private get $currentDotButton() {
      return $(this.$slidesNavigationButtons.get(this.currentSlideIndex));
    }

    private _currentSlideSubscription = this.currentSlide.subscribe(() => {
      this.$slidesNavigation.find(`.${activeClass}`).removeClass(activeClass);

      this.$currentDotButton.addClass(activeClass);
    });

    initialize() {
      super.initialize();
      this.$slidesNavigationButtons.on('mouseover', this.onDotButtonClick);
    }

    destroy() {
      this.$slidesNavigationButtons.off('mouseover', this.onDotButtonClick);
      this._currentSlideSubscription.unsubscribe();
    }

    private onDotButtonClick = (event: JQuery.ClickEvent) => {
      const index = this.$slidesNavigationButtons.index(event.currentTarget);
      this.goToSlide(index);
    };
  };
};

export default SlidesNavigation;
