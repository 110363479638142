import bind from 'bind-decorator';
import Component from './component';

interface JobsResponseJSON {
  jobs: JobResponseJSON[]
}

interface JobResponseJSON {
  title: string;
  category_name: string;
  description_url: string;
  location: string;
  location_icon: string;
  schedule: string;
}

class JobsList extends Component {
  private readonly $jobsContainer = this.$element.find('.careers__content');
  private readonly $jobCategoriesSelect = this.$element.find('#job_categories');
  private readonly $locationsSelect = this.$element.find('#locations');
  private readonly $schedulesCheckboxes = this.$element.find("input[name='schedule_ids']");
  private readonly $noResultsMessage = this.$element.find('.careers__no-filtered-results');
  private readonly apiUrl = this.$element.data('jobs-url');
  private jobCategoryIdSelected = null;
  private locationIdSelected = null;
  private schedulesChecked = new Set();

  initialize() {
    this.$jobCategoriesSelect.on('change', this.onCategoryChange);
    this.$locationsSelect.on('change', this.onLocationChange);
    this.$schedulesCheckboxes.on('click', this.onScheduleClick);
  }

  destroy() {
    this.$jobCategoriesSelect.off('change', this.onCategoryChange);
    this.$locationsSelect.off('change', this.onLocationChange);
    this.$schedulesCheckboxes.off('click', this.onScheduleClick);
  }

  @bind
  private onCategoryChange(event: JQuery.ChangeEvent) {
    this.jobCategoryIdSelected = event.target.value;
    this.fetchJobs();
  }

  @bind
  private onLocationChange(event: JQuery.ChangeEvent) {
    this.locationIdSelected = event.target.value;
    this.fetchJobs();
  }

  @bind
  private onScheduleClick(event: JQuery.ClickEvent) {
    if (event.target.checked) {
      this.schedulesChecked.add(event.target.value);
    } else {
      this.schedulesChecked.delete(event.target.value);
    }
    this.fetchJobs();
  }

  @bind
  private handleSuccess(response: JobsResponseJSON) {
    this.$jobsContainer.empty();

    if (response.jobs.length > 0) {
      this.$noResultsMessage.hide();
      this.renderJobs(response.jobs);
    } else {
      this.$noResultsMessage.show();
    }
  }

  private fetchJobs() {
    $.ajax({
      url: this.apiUrl,
      dataType: 'json',
      type: 'GET',
      data: {
        // eslint-disable-next-line camelcase
        job_category_id: this.jobCategoryIdSelected,
        // eslint-disable-next-line camelcase
        location_id: this.locationIdSelected,
        'schedule_ids[]': Array.from(this.schedulesChecked.values())
      },
      traditional: true,
      success: this.handleSuccess.bind(this),
      error: this.handleErrors.bind(this)
    });
  }

  private renderJobs(jobs: JobResponseJSON[]) {
    jobs.forEach( (job: JobResponseJSON) => {
      const jobItem = this.renderJob(job);
      this.$jobsContainer.append(jobItem);
    });
  }

  private renderJob(job: JobResponseJSON): string {
    return `
      <a href="${job.description_url}" target="_blank" class="careers__content-item">
        <div class="careers__content-item-title">
          ${job.category_name} - ${job.title}
        </div>
        <div class="careers__content-item-info">
          <div class="careers__content-item-location">
            <img src="${job.location_icon}" class="careers__content-item-location-icon">
            ${job.location}
          </div>
          <div class="careers__content-item-schedule">
            ${job.schedule}
          </div>
        </div>
      </a>
    `;
  }

  @bind
  private handleErrors(_response: object) {
    this.$jobsContainer.empty();
    this.$noResultsMessage.show();
  }
}

export default JobsList;
