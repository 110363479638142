import bind from 'bind-decorator';
import Component from './component';
import startOfToday from 'date-fns/startOfToday';
import sub from 'date-fns/sub';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import * as Cookies from 'js-cookie';

const OPEN_CLASS_NAME = 'age-validation-dialog--open';
const ERROR_CLASS_NAME = 'age-validation-dialog--error';
const COOKIE_NAME = 'hasLegalDrinkingAge';
const VALID_DAYS = 30;

enum Province {
  Alberta = 'alberta',
  BritishColumbia = 'british-columbia',
  Manitoba = 'manitoba',
  NewBrunswick = 'new-brunswick',
  NewfoundlandAndLabrador = 'newfoundland-and-labrador',
  NorthwestTerritories = 'northwest-territories',
  NovaScotia = 'nova-scotia',
  Nunavut = 'nunavut',
  Ontario = 'ontario',
  PrinceEdwardIsland = 'prince-edward-island',
  Quebec = 'quebec',
  Saskatchewan = 'saskatchewan',
  Yukon = 'yukon',
}

const ProvinceAge = {
  [Province.Alberta]: 18,
  [Province.BritishColumbia]: 19,
  [Province.Manitoba]: 18,
  [Province.NewBrunswick]: 19,
  [Province.NewfoundlandAndLabrador]: 19,
  [Province.NorthwestTerritories]: 19,
  [Province.NovaScotia]: 19,
  [Province.Nunavut]: 19,
  [Province.Ontario]: 19,
  [Province.PrinceEdwardIsland]: 19,
  [Province.Quebec]: 18,
  [Province.Saskatchewan]: 19,
  [Province.Yukon]: 19
};

class AgeValidationDialog extends Component {
  form = this.element.querySelector<HTMLFormElement>('[data-form]')!;

  get isLegal() {
    return Cookies.get(COOKIE_NAME) === 'yes';
  }

  set isLegal(value: boolean) {
    Cookies.set(COOKIE_NAME, value ? 'yes' : 'no', {expires: VALID_DAYS});
  }

  initialize() {
    this.$element.toggleClass(OPEN_CLASS_NAME, !this.isLegal);
    this.form.addEventListener('submit', this.onSubmit);
  }

  @bind
  private onSubmit(e: Event) {
    e.preventDefault();
    this.$element.removeClass(ERROR_CLASS_NAME);

    const data = new FormData(this.form);

    const province = data.get('province') as Province;
    const minimumAge = ProvinceAge[province];
    const minimumBirthDate = sub(startOfToday(), {years: minimumAge});

    const day = data.get('date[day]') as string;
    const month = data.get('date[month]') as string;
    const year = data.get('date[year]') as string;
    const birthDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    );

    this.isLegal
      = isBefore(birthDate, minimumBirthDate)
      || isSameDay(birthDate, minimumBirthDate);

    if (this.isLegal) {
      this.$element.removeClass(OPEN_CLASS_NAME);
    } else {
      this.$element.addClass(ERROR_CLASS_NAME);
    }
  }
}

export default AgeValidationDialog;
