export const watchMediaQuery = (
  mediaQuery: MediaQueryList,
  callback: (event: MediaQueryListEvent) => void
) => {
  if (typeof mediaQuery.addEventListener === 'undefined') {
    mediaQuery.addListener(callback);
  } else {
    mediaQuery.addEventListener('change', callback);
  }
};

export const unwatchMediaQuery = (
  mediaQuery: MediaQueryList,
  callback: (event: MediaQueryListEvent) => void
) => {
  if (typeof mediaQuery.removeEventListener === 'undefined') {
    mediaQuery.removeListener(callback);
  } else {
    mediaQuery.removeEventListener('change', callback);
  }
};

export const sticky = (
  element: HTMLElement,
  isSticky = ({y}: DOMRect) => y <= 0
) => {
  let pendingApplyStickyness = false;
  let currentAnimationFrame = -1;

  const onScrollOrResize = () => {
    if (pendingApplyStickyness) return;
    pendingApplyStickyness = true;
    currentAnimationFrame = window.requestAnimationFrame(() => {
      pendingApplyStickyness = false;

      const rect = element.getBoundingClientRect();
      element.classList.toggle('sticky', isSticky(rect));
    });
  };

  window.addEventListener('scroll', onScrollOrResize);
  window.addEventListener('resize', onScrollOrResize);

  return () => {
    window.cancelAnimationFrame(currentAnimationFrame);
    window.removeEventListener('scroll', onScrollOrResize);
    window.removeEventListener('resize', onScrollOrResize);
  };
};
