import {styler, value, spring, SpringProps} from 'popmotion';
import bind from 'bind-decorator';
import Component from './component';
import {ResizeObserver} from '@juggle/resize-observer';

const springProps: SpringProps = {
  stiffness: 200,
  damping: 40,
  restDelta: 1,
  restSpeed: 10
};

class Drawer extends Component {
  private $toggler = this.$element.find('[data-drawer-toggler]');

  private $contentWrapper = this.$element.find('[data-drawer-content-wrapper]');
  private contentWrapper = this.$contentWrapper.get(0);
  private contentWrapperStyler = styler(this.contentWrapper);
  private contentWrapperHeightValue = value(0, (height: number) =>
    this.contentWrapperStyler.set('height', height)
  );

  private $content = this.$element.find('[data-drawer-content]');
  private content = this.$content.get(0);
  private contentHeight = this.$content.outerHeight();

  private $arrow = this.$element.find('[data-drawer-arrow]');
  private arrow = this.$arrow.get(0);
  private arrowStyler = styler(this.arrow);
  private arrowRotateValue = value(0, (rotate: number) =>
    this.arrowStyler.set('rotate', rotate)
  );

  private resizeObserver = new ResizeObserver(() => {
    this.contentHeight = this.$content.outerHeight();
  });

  private _isOpen = false;

  private get isOpen() {
    return this._isOpen;
  }

  private set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
    this.render();
  }

  initialize() {
    this.$toggler.on('click', this.toggle);
    this.resizeObserver.observe(this.content);
  }

  destroy() {
    this.$toggler.off('click', this.toggle);
    this.resizeObserver.disconnect();
  }

  @bind
  private toggle() {
    this.isOpen = !this.isOpen;
  }

  private render() {
    spring({
      ...springProps,
      velocity: this.contentWrapperHeightValue.getVelocity(),
      from: this.contentWrapperHeightValue.get(),
      to: this.isOpen ? this.contentHeight : 0
    }).start(this.contentWrapperHeightValue);

    spring({
      ...springProps,
      velocity: this.arrowRotateValue.getVelocity(),
      from: this.arrowRotateValue.get(),
      // eslint-disable-next-line no-magic-numbers
      to: this.isOpen ? -180 : 0
    }).start(this.arrowRotateValue);
  }
}

export default Drawer;
