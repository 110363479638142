import {
  pointer,
  styler,
  listen,
  value,
  spring,
  ColdSubscription
} from 'popmotion';
import {BehaviorSubject} from 'rxjs';
import bind from 'bind-decorator';
import {PointerProps} from 'popmotion/lib/input/pointer/types';
import Component from './component';
import {ResizeObserver} from '@juggle/resize-observer';
import SlidesNavigation from './mixins/slides-navigation';

const xPointer = (initialX: number) => {
  return pointer({x: initialX}).pipe(({x}: PointerProps) => x);
};

const rubberbandEffect = (from: number, to: number, progress: number) => {
  return -progress * from + progress * to + from;
};

enum Direction {
  Previous = 1,
  None = 0,
  Next = -1,
}

const ACTIVE_DOT_CLASS = 'images-carousel__dot-button--active';

class ImagesCarousel extends Component {
  protected $container = this.$element.find('[data-container]').length
    ? this.$element.find('[data-container]')
    : this.$element;
  protected container = this.$container.get(0)!;

  protected $draggableContent = this.$element.find('[data-draggable-content]');
  protected draggableContent = this.$draggableContent.get(0);

  private width = this.$container.width()!;

  private slideCount = this.$draggableContent.children().length;

  currentSlide = new BehaviorSubject(0);

  get currentSlideIndex() {
    return this.currentSlide.value;
  }

  private get nextSlideIndex() {
    return Math.min(this.slideCount - 1, this.currentSlide.value + 1);
  }

  private get previousSlideIndex() {
    return Math.max(0, this.currentSlide.value - 1);
  }

  private get currentSlideX() {
    return this.getSlideX(this.currentSlide.value);
  }

  private get x() {
    return this.xValue.get() as number;
  }

  private get velocity() {
    return this.xValue.getVelocity();
  }

  private get direction(): Direction {
    return Math.sign(this.velocity) || Math.sign(this.x - this.currentSlideX);
  }

  protected get minimumX() {
    return Math.max(this.slideCount * this.width - this.width, 0) * -1;
  }

  protected maximumX = 0;

  // eslint-disable-next-line no-magic-numbers
  protected dragThreshold = 3;
  protected isDragging = new BehaviorSubject(false);

  private tracker: ColdSubscription | null = null;

  private styler = styler(this.draggableContent);
  protected xValue = value(0, (x: number) => this.styler.set('x', x));

  private downListener = listen(
    this.draggableContent,
    'mousedown touchstart'
  ).start(this.startTracking);

  private upListener = listen(document, 'mouseup touchend').start(
    this.stopTracking
  );

  private isDraggingSubscription = this.isDragging.subscribe((isDragging) => {
    this.$container.toggleClass('dragging', isDragging);
  });

  private currentSlideSubscription = this.currentSlide.subscribe(
    this.onCurrentSlideChange
  );

  private resizeObserver = new ResizeObserver(([entry]) => {
    const [size] = entry.borderBoxSize;
    this.width = size.inlineSize;
    this.xValue.update(this.currentSlideX);
  });

  initialize() {
    this.resizeObserver.observe(this.container);
  }

  destroy() {
    this.downListener.stop();
    this.upListener.stop();
    this.isDraggingSubscription.unsubscribe();
    this.currentSlideSubscription.unsubscribe();
    this.resizeObserver.disconnect();
  }

  @bind
  protected onCurrentSlideChange() {
    spring({
      stiffness: 200,
      damping: 40,
      restDelta: 1,
      restSpeed: 10,
      velocity: this.velocity,
      from: this.x,
      to: this.currentSlideX
    }).start(this.xValue);
  }

  @bind
  protected goToNextSlide() {
    this.currentSlide.next(this.nextSlideIndex);
  }

  @bind
  protected goToPreviousSlide() {
    this.currentSlide.next(this.previousSlideIndex);
  }

  private goToCurrentSlide() {
    this.currentSlide.next(this.currentSlideIndex);
  }

  goToSlide(slideIndex: number) {
    this.currentSlide.next(
      Math.min(this.slideCount - 1, Math.max(0, slideIndex))
    );
  }

  private goToSlideFromDirection(direction: Direction) {
    switch (direction) {
      case Direction.Next:
        return this.goToNextSlide();
      case Direction.Previous:
        return this.goToPreviousSlide();
      case Direction.None:
        return this.goToCurrentSlide();
    }
  }

  @bind
  private applyRubberbandEffect(x: number) {
    if (x < this.minimumX) {
      // eslint-disable-next-line no-magic-numbers
      return rubberbandEffect(this.minimumX, x, 0.35);
    }

    if (x > this.maximumX) {
      // eslint-disable-next-line no-magic-numbers
      return rubberbandEffect(this.maximumX, x, 0.35);
    }

    return x;
  }

  @bind
  private applyDragThreshold(initialX: number) {
    return (x: number) => {
      const isDragging
        = this.minimumX < 0
        && (this.isDragging.value || Math.abs(initialX - x) > this.dragThreshold);

      this.isDragging.next(isDragging);

      return isDragging;
    };
  }

  @bind
  private startTracking() {
    const initialX = this.styler.get('x');
    this.tracker = xPointer(initialX)
      .filter(this.applyDragThreshold(initialX))
      .pipe(this.applyRubberbandEffect)
      .start(this.xValue);
  }

  @bind
  private stopTracking() {
    if (!this.tracker) return;

    this.tracker.stop();
    this.goToSlideFromDirection(this.direction);
    this.isDragging.next(false);
  }

  private getSlideX(slideIndex: number) {
    return slideIndex * this.width * -1;
  }
}

export const ImagesCarouselWithDotsNavigation = SlidesNavigation(
  ImagesCarousel,
  ACTIVE_DOT_CLASS
);

export default ImagesCarousel;
