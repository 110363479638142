import bind from 'bind-decorator';
import Component from './component';

const ACTIVE_CLASS = 'active';
const COLLAPSABLE_CONFIG = {
  duration: 150
};

class Collapsable extends Component {
  private $collapsable = this.$element;
  private $trigger = this.$collapsable.find('.collapsable-trigger');
  private $target = this.$collapsable.find('.collapsable-target');
  private isExclusive = this.$collapsable.hasClass('collapsable-exclusive');

  initialize() {
    this.$trigger.on('click', this.onTriggerClick);
    this.updateVisibilityFromHash();
  }

  destroy() {
    this.$trigger.off('click');
  }

  @bind
  private onTriggerClick() {
    if (this.isExclusive) this.hideSiblings();

    if (this.$collapsable.hasClass(ACTIVE_CLASS)) return this.hide();
    this.show();
  }

  private updateVisibilityFromHash() {
    const containerId = this.$collapsable.attr('id');
    if (!location.hash || !containerId) return;
    if (!location.hash.includes(`#${containerId}`)) return;

    this.show();
  }

  private show() {
    this.$collapsable.addClass(ACTIVE_CLASS);
    this.$target.slideDown(COLLAPSABLE_CONFIG);
    this.$target.find(':input:first').focus();
  }

  private hide() {
    this.$collapsable.removeClass(ACTIVE_CLASS);
    this.$target.slideUp(COLLAPSABLE_CONFIG);
  }

  private hideSiblings() {
    const $siblings = this.$collapsable.siblings();
    $siblings.removeClass(ACTIVE_CLASS);
    $siblings.find('.collapsable-target').slideUp(COLLAPSABLE_CONFIG);
  }
}

export default Collapsable;
