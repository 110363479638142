import bind from 'bind-decorator';
import Carousel from './carousel';
import {watchMediaQuery, unwatchMediaQuery} from './utils';

const BIG_SCREEN_TILE_GAP = 92;
const SMALL_SCREEN_TILE_GAP = 28;

class MixpacksCarousel extends Carousel {
  protected smallScreenMediaQuery = matchMedia('(max-width: 480px)');
  protected tileGap = this.smallScreenMediaQuery.matches
    ? SMALL_SCREEN_TILE_GAP
    : BIG_SCREEN_TILE_GAP;

  initialize() {
    super.initialize();
    watchMediaQuery(this.smallScreenMediaQuery, this.onScreenTypeChange);
  }

  destroy() {
    super.destroy();
    unwatchMediaQuery(this.smallScreenMediaQuery, this.onScreenTypeChange);
  }

  @bind
  onScreenTypeChange(event: MediaQueryListEvent) {
    this.tileGap = event.matches ? SMALL_SCREEN_TILE_GAP : BIG_SCREEN_TILE_GAP;
  }
}

export default MixpacksCarousel;
