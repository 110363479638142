import bind from 'bind-decorator';
import ImagesCarousel from './images-carousel';
import SlidesNavigation from './mixins/slides-navigation';
import * as Turbolinks from 'turbolinks';

const COOKIE_NAME = 'restaurant_slug';
const COOKIE_EXPIRATION_IN_DAYS = 10;
const NUMBER_OF_HOURS_IN_A_DAY = 24;

class RestaurantsCarouselSelector extends ImagesCarousel {
  protected $previousButton = this.$element.find('[data-carousel-previous]');
  protected $nextButton = this.$element.find('[data-carousel-next]');
  protected $restaurantLink = this.$element.find(
    '.restaurants-carousel__restaurant-link'
  );
  protected $restaurantNames = this.$element.find(
    '.restaurants-carousel-list-item__button'
  );
  protected $restaurantImages = this.$element.find(
    '.restaurants-carousel-image__link'
  );

  private $currentSlide = this.$element.find('[data-current-slide]');

  private _currentSlideSubscription = this.currentSlide.subscribe(
    this.onSlideChange
  );

  initialize() {
    super.initialize();
    this.$previousButton.on('click', this.goToPreviousSlide);
    this.$nextButton.on('click', this.goToNextSlide);
    this.$restaurantNames.on('click', this.onRestaurantClick);
    this.$restaurantImages.on('click', this.onRestaurantClick);
    this.$restaurantLink.on('click', this.onRestaurantsLinkClick);
  }

  destroy() {
    super.destroy();
    this.$previousButton.off('click');
    this.$nextButton.off('click');
    this.$restaurantNames.off('click', this.onRestaurantClick);
    this.$restaurantImages.off('click', this.onRestaurantClick);
    this.$restaurantLink.off('click', this.onRestaurantsLinkClick);
    this._currentSlideSubscription.unsubscribe();
  }

  @bind
  onSlideChange() {
    this.renderCurrentSlide();
  }

  renderCurrentSlide() {
    const currentSlide = this.currentSlideIndex + 1;
    this.$currentSlide.html(currentSlide.toString().padStart(2, '0'));
  }

  @bind
  private onRestaurantClick(event: JQuery.ClickEvent) {
    const url = $(event.target).attr('data-restaurants-url');
    const slug = event.target.id;

    if (!event.target || !url) return;

    this.goToRestaurant(url, slug);
  }

  @bind
  private onRestaurantsLinkClick() {
    const url = this.$draggableContent
      .children()
      .get(this.currentSlideIndex).dataset.restaurantsUrl!;

    const slug = this.$draggableContent
      .children()
      .get(this.currentSlideIndex).dataset.restaurantSlug!;

    if (!url || !slug) return;

    this.goToRestaurant(url, slug);
  }

  private setCookie(slug: string) {
    if (!slug) return;

    const expires = new Date();
    const expiration
      = COOKIE_EXPIRATION_IN_DAYS * NUMBER_OF_HOURS_IN_A_DAY * 60 * 60 * 1000;

    expires.setTime(expires.getTime() + expiration);

    document.cookie = `${COOKIE_NAME}=${slug};path=/;expires=${expires.toUTCString()}`;
  }

  private goToRestaurant(url: string, slug: string) {
    this.setCookie(slug);

    Turbolinks.visit(url);
  }
}

export default SlidesNavigation(
  RestaurantsCarouselSelector,
  'restaurants-carousel__list-item--active'
);
