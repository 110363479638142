import bind from 'bind-decorator';
import Component from './component';
import smoothscroll from 'smoothscroll-polyfill';

class ScrollToRestaurantsCarousel extends Component {
  initialize() {
    smoothscroll.polyfill();
    this.$element.on('click', this.onClick);
  }

  destroy() {
    this.$element.off('click', this.onClick);
  }

  @bind
  private onClick(_event: JQuery.ClickEvent) {
    const elementToScrollTo = document.getElementById('restaurants-carousel');
    if (!elementToScrollTo) return;

    const offsetPosition = elementToScrollTo.offsetTop;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}

export default ScrollToRestaurantsCarousel;
