import bind from 'bind-decorator';
import {styler} from 'popmotion';
import Carousel from './carousel';
import {watchMediaQuery, unwatchMediaQuery} from './utils';

const BIG_SCREEN_TILE_GAP = 83;
const SMALL_SCREEN_TILE_GAP = 42;

class PromotionsCarousel extends Carousel {
  private $progressIndicator = this.$element.find(
    '[data-carousel-progress-indicator]'
  );
  protected progressIndicator = this.$progressIndicator.get(0);

  protected smallScreenMediaQuery = matchMedia('(max-width: 980px)');
  protected tileGap = this.smallScreenMediaQuery.matches
    ? SMALL_SCREEN_TILE_GAP
    : BIG_SCREEN_TILE_GAP;

  private progressIndicatorStyler = styler(this.progressIndicator);
  private xValueSubscription = this.xValue.subscribe((x: number) => {
    const progress = (x / Math.abs(this.min)) * -1;
    const max = this.containerWidth - this.$progressIndicator.width()!;
    const progressX = progress * max;
    this.progressIndicatorStyler.set('x', progressX);
  });

  initialize() {
    super.initialize();
    watchMediaQuery(this.smallScreenMediaQuery, this.onScreenTypeChange);
  }

  destroy() {
    super.destroy();
    unwatchMediaQuery(this.smallScreenMediaQuery, this.onScreenTypeChange);
    this.xValueSubscription.unsubscribe();
  }

  @bind
  onScreenTypeChange(event: MediaQueryListEvent) {
    this.tileGap = event.matches ? SMALL_SCREEN_TILE_GAP : BIG_SCREEN_TILE_GAP;
  }
}

export default PromotionsCarousel;
