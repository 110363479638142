import bind from 'bind-decorator';
import Component from './component';

class ScrollToSelector extends Component {
  initialize() {
    this.$element.on('change', this.onChange);
  }

  destroy() {
    this.$element.off('change', this.onChange);
  }

  @bind
  private onChange(event: JQuery.ChangeEvent) {
    const elementToScrollTo = $(`#${event.target.value}`).get(0);
    const offset = 100;
    const elementPosition = elementToScrollTo.offsetTop;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}

export default ScrollToSelector;
