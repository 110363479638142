import bind from 'bind-decorator';
import Component from './component';

const AUTO_CLOSE_DELAY = 5000;
const CLOSED_CLASS = 'flash--closed';

class Flash extends Component {
  timer = window.setTimeout(this.close, AUTO_CLOSE_DELAY);

  initialize() {
    this.$element.on('click', this.onClick);
  }

  destroy() {
    window.clearTimeout(this.timer);
  }

  @bind
  private close() {
    this.$element.addClass(CLOSED_CLASS);
  }

  @bind
  private onClick() {
    this.close();
    window.clearTimeout(this.timer);
  }
}

export default Flash;
