/* global module */

import 'jquery/src/jquery';
import ComponentsManager from './components-manager';

let ComponentsManagerInstance = new ComponentsManager();

document.addEventListener('turbolinks:load', () => {
  ComponentsManagerInstance.initializeComponents();
});

document.addEventListener('turbolinks:before-cache', () => {
  ComponentsManagerInstance.destroyComponents();
});

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('popstate', () => {
    location.reload();
  });
});

if (module.hot as any) {
  module.hot.accept('./components-manager', () => {
    ComponentsManagerInstance.destroyComponents();
    ComponentsManagerInstance = new ComponentsManager();
    ComponentsManagerInstance.initializeComponents();
  });
}
