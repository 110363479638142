import * as Turbolinks from 'turbolinks';
import bind from 'bind-decorator';
import Component from './component';

class RestaurantMenuSelectSelector extends Component {
  private readonly $select = this.$element.find('.restaurant-menu-selector__select');

  initialize() {
    this.$select.on('change', this.onChange);
  }

  destroy() {
    this.$select.off('change', this.onChange);
  }

  @bind
  private onChange(event: JQuery.ChangeEvent) {
    const url = event.target.value;
    const _slug = this.$select.find(':selected');

    Turbolinks.visit(url);
  }
}

export default RestaurantMenuSelectSelector;
