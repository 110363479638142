import * as Turbolinks from 'turbolinks';
import bind from 'bind-decorator';
import Component from './component';

const COOKIE_NAME = 'restaurant_slug';
const COOKIE_EXPIRATION_IN_DAYS = 10;
const NUMBER_OF_HOURS_IN_A_DAY = 24;

class RestaurantSelectSelector extends Component {
  private readonly $button = this.$element.find('.restaurant-selector__submit');
  private readonly $select = this.$element.find('.restaurant-selector__select');

  initialize() {
    if (this.$button.length) {
      this.$button.on('click', this.onSubmitClick);
    } else {
      this.$select.on('change', this.onChange);
    }
  }

  destroy() {
    if (this.$button.length) {
      this.$button.off('click', this.onSubmitClick);
    } else {
      this.$select.off('change', this.onChange);
    }
  }

  @bind
  private onChange(event: JQuery.ChangeEvent) {
    const url = event.target.value;
    const slug = this.$select.find(':selected').data('restaurant-slug');

    this.goToRestaurant(url, slug);
  }

  @bind
  private onSubmitClick() {
    const url = this.$select.val()?.toString();
    const slug = this.$select.find(':selected').data('restaurant-slug');

    if (!url) return;

    this.goToRestaurant(url, slug);
  }

  private setCookie(slug: string) {
    if (!slug) return;

    const expires = new Date();
    const expiration
      = COOKIE_EXPIRATION_IN_DAYS * NUMBER_OF_HOURS_IN_A_DAY * 60 * 60 * 1000;

    expires.setTime(expires.getTime() + expiration);

    document.cookie = `${COOKIE_NAME}=${slug};path=/;expires=${expires.toUTCString()}`;
  }

  private goToRestaurant(url: string, slug: string) {
    this.setCookie(slug);

    Turbolinks.visit(url);
  }
}

export default RestaurantSelectSelector;
