import {sticky} from './utils';
import Component from './component';

class MainNav extends Component {
  private destroySticky = sticky(this.element);

  destroy() {
    this.destroySticky();
  }
}

export default MainNav;
