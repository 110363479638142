import {
  pointer,
  styler,
  listen,
  value,
  inertia,
  spring,
  ColdSubscription
} from 'popmotion';
import bind from 'bind-decorator';
import {PointerProps} from 'popmotion/lib/input/pointer/types';
import {ResizeObserver} from '@juggle/resize-observer';
import Component from './component';
import {BehaviorSubject} from 'rxjs';

const xPointer = (initialX: number) => {
  return pointer({x: initialX}).pipe(({x}: PointerProps) => x);
};

const rubberbandEffect = (from: number, to: number, progress: number) => {
  return -progress * from + progress * to + from;
};

const isOdd = (value: number) => {
  return value % 2 === 1;
};

abstract class Carousel extends Component {
  protected $draggableContent = this.$element.find('[data-carousel-content]');
  protected draggableContent = this.$draggableContent.get(0);

  protected $previousButton = this.$element.find('[data-carousel-previous]');
  protected $nextButton = this.$element.find('[data-carousel-next]');

  protected containerWidth = this.$element.width()!;
  protected draggableContentWidth = this.$draggableContent.width()!;
  protected draggableContentOuterWidth = this.$draggableContent.outerWidth()!;

  private isOverflowing = new BehaviorSubject(
    this.draggableContentOuterWidth > this.containerWidth
  );

  protected get padding() {
    return (this.draggableContentOuterWidth - this.draggableContentWidth) / 2;
  }

  protected get min() {
    return (
      Math.max(this.draggableContentOuterWidth - this.containerWidth, 0) * -1
    );
  }

  protected max = 0;

  protected abstract tileGap: number;

  // eslint-disable-next-line no-magic-numbers
  protected dragThreshold = 3;
  protected isDragging = new BehaviorSubject(false);

  private tracker: ColdSubscription | null = null;

  private styler = styler(this.draggableContent);
  protected xValue = value(0, (x: number) => this.styler.set('x', x));

  private downListener = listen(
    this.draggableContent,
    'mousedown touchstart'
  ).start(this.startTracking);

  private upListener = listen(document, 'mouseup touchend').start(
    this.stopTracking
  );

  private resizeObserver = new ResizeObserver(([entry]) => {
    const [size] = entry.borderBoxSize;
    this.containerWidth = size.inlineSize;
    this.draggableContentWidth = this.$draggableContent.width()!;
    this.draggableContentOuterWidth = this.$draggableContent.outerWidth()!;
    this.isOverflowing.next(
      this.draggableContentOuterWidth > this.containerWidth
    );
    this.xValue.update(this.getTarget(this.xValue.get() as number));
  });

  private isOverflowingSubscription = this.isOverflowing.subscribe(
    (isOverflowing) => {
      this.$element.toggleClass('overflowing', isOverflowing);
    }
  );

  private isDraggingSubscription = this.isDragging.subscribe((isDragging) => {
    this.$element.toggleClass('dragging', isDragging);
  });

  initialize() {
    this.$draggableContent.children().on('click', () => !this.isDragging.value);

    this.$draggableContent.on('dragstart', () => false);
    this.$previousButton.on('click', this.goToPrevious);
    this.$nextButton.on('click', this.goToNext);

    this.resizeObserver.observe(this.element);
  }

  destroy() {
    this.downListener.stop();
    this.upListener.stop();
    this.$draggableContent.children().off('click');
    this.$draggableContent.off('dragstart');
    this.$previousButton.off('click');
    this.$nextButton.off('click');
    this.resizeObserver.disconnect();
    this.isOverflowingSubscription.unsubscribe();
    this.isDraggingSubscription.unsubscribe();
  }

  @bind
  protected goToPrevious() {
    const x = this.xValue.get() as number;
    const target = Math.min(this.max, this.getTarget(x + this.containerWidth));
    this.goTo(target);
  }

  @bind
  protected goToNext() {
    const x = this.xValue.get() as number;
    const target = Math.max(this.min, this.getTarget(x - this.containerWidth));
    this.goTo(target);
  }

  protected goTo(x: number) {
    spring({
      velocity: this.xValue.getVelocity(),
      stiffness: 200,
      damping: 40,
      restDelta: 1,
      restSpeed: 10,
      from: this.xValue.get(),
      to: x
    }).start(this.xValue);
  }

  @bind
  private applyRubberbandEffect(x: number) {
    if (x < this.min) {
      // eslint-disable-next-line no-magic-numbers
      return rubberbandEffect(this.min, x, 0.35);
    }

    if (x > 0) {
      // eslint-disable-next-line no-magic-numbers
      return rubberbandEffect(0, x, 0.35);
    }

    return x;
  }

  @bind
  private applyDragThreshold(initialX: number) {
    return (x: number) => {
      const isDragging
        = this.isOverflowing.value
        && (this.isDragging.value || Math.abs(initialX - x) > this.dragThreshold);

      this.isDragging.next(isDragging);

      return isDragging;
    };
  }

  @bind
  private startTracking() {
    const initialX = this.styler.get('x');
    this.tracker = xPointer(initialX)
      .filter(this.applyDragThreshold(initialX))
      .pipe(this.applyRubberbandEffect)
      .start(this.xValue);
  }

  private getTarget(x: number) {
    const tileWidth
      = this.$draggableContent.children().first().outerWidth()! + this.tileGap;
    const visibleTileCount = Math.ceil(this.containerWidth / tileWidth);
    const offset
      = (isOdd(visibleTileCount)
        ? this.containerWidth - visibleTileCount * tileWidth
        : this.containerWidth % tileWidth) / 2;

    const target = Math.round((x - this.padding) / tileWidth) * tileWidth;

    return target === this.min || target === this.max
      ? target
      : target + offset - this.padding + this.tileGap / 2;
  }

  @bind
  private stopTracking(_event: MouseEvent | TouchEvent) {
    if (!this.tracker) return;

    this.tracker.stop();

    inertia({
      from: this.xValue.get(),
      velocity: this.xValue.getVelocity(),
      power: 0.4,
      timeConstant: 200,
      min: this.min,
      max: this.max,
      bounceStiffness: 200,
      bounceDamping: 40,
      restDelta: 1,
      restSpeed: 10,
      modifyTarget: (x: number) => {
        if (x > this.max || x < this.min) return x;
        return this.getTarget(x);
      }
    }).start(this.xValue);

    setTimeout(() => this.isDragging.next(false));
  }
}

export default Carousel;
