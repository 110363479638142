import Component from './component';
import {sticky} from './utils';

const OFFSET = 73;

class RestaurantNav extends Component {
  private destroySticky = sticky(this.element, ({y}) => y <= OFFSET);

  destroy() {
    this.destroySticky();
  }
}

export default RestaurantNav;
