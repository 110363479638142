import bind from 'bind-decorator';
import Component from './component';

const ESCAPE_KEY_CODE = 27;
const BODY_MENU_OPENED_CLASS = 'menu-opened';
const OVERLAY_MENU_SELECTOR = '.overlay-menu';
const OVERLAY_MENU_VISIBLE_CLASS = 'overlay-menu--visible';
const TOGGLE_SELECTOR = '.overlay-menu__toggle';
const EXIT_SELECTOR = '.overlay-menu__exit';

class OverlayMenu extends Component {
  private readonly $body = $('body');
  private readonly $exitSelector = $(EXIT_SELECTOR);
  private readonly $overlayMenu = $(OVERLAY_MENU_SELECTOR);
  private readonly $toggleSelector = $(TOGGLE_SELECTOR);
  private scrollPosition = 0;

  initialize() {
    this.$toggleSelector.on('click', this.showOverlay);
    this.$exitSelector.on('click', this.hideOverlay);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  destroy() {
    this.$toggleSelector.off('click', this.showOverlay);
    this.$exitSelector.off('click', this.hideOverlay);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  @bind
  private handleKeyUp(event: KeyboardEvent) {
    const isOverlayVisible = this.$overlayMenu.hasClass(
      OVERLAY_MENU_VISIBLE_CLASS
    );

    if (event.keyCode === ESCAPE_KEY_CODE && isOverlayVisible) {
      this.hideOverlay();
    }
  }

  @bind
  private hideOverlay() {
    this.$body.removeClass(BODY_MENU_OPENED_CLASS);
    this.$overlayMenu.removeClass(OVERLAY_MENU_VISIBLE_CLASS);

    if (!this.scrollPosition) return;

    window.scrollTo(0, this.scrollPosition * -1);
  }

  @bind
  private showOverlay() {
    this.scrollPosition = document.body.getBoundingClientRect().top;

    this.$body.toggleClass(BODY_MENU_OPENED_CLASS);
    this.$overlayMenu.toggleClass(OVERLAY_MENU_VISIBLE_CLASS);
  }
}

export default OverlayMenu;
