import bind from 'bind-decorator';
import ImagesCarousel from './images-carousel';
import SlidesNavigation from './mixins/slides-navigation';

class RestaurantsCarousel extends ImagesCarousel {
  protected $previousButton = this.$element.find('[data-carousel-previous]');
  protected $nextButton = this.$element.find('[data-carousel-next]');
  protected $restaurantLinks = this.$element.find(
    '.restaurants-carousel__restaurant-link'
  );

  $currentSlide = this.$element.find('[data-current-slide]');

  private _currentSlideSubscription = this.currentSlide.subscribe(
    this.onSlideChange
  );

  initialize() {
    super.initialize();
    this.$previousButton.on('click', this.goToPreviousSlide);
    this.$nextButton.on('click', this.goToNextSlide);
  }

  destroy() {
    super.destroy();
    this.$previousButton.off('click');
    this.$nextButton.off('click');
    this._currentSlideSubscription.unsubscribe();
  }

  @bind
  onSlideChange() {
    this.renderRestaurantLinks();
    this.renderCurrentSlide();
  }

  renderCurrentSlide() {
    const currentSlide = this.currentSlideIndex + 1;
    this.$currentSlide.html(currentSlide.toString().padStart(2, '0'));
  }

  renderRestaurantLinks() {
    const restaurantPath = this.$draggableContent
      .children()
      .get(this.currentSlideIndex).dataset.restaurantPath!;

    this.$restaurantLinks.each((_index, restaurantLink) => {
      restaurantLink.setAttribute('href', restaurantPath);
    });
  }
}

export default SlidesNavigation(
  RestaurantsCarousel,
  'restaurants-carousel__list-item--active'
);
