import type Component from './component';
import AgeValidationDialog from './age-validation-dialog';
import BeerBackButton from './beer-back-button';
import BeerCategoriesCarousel from './beer-categories-carousel';
import BeersCarousel from './beers-carousel';
import BeersQuickNav from './beers-quick-nav';
import Collapsable from './collapsable';
import Drawer from './drawer';
import ExclusiveBeersCarousel from './exclusive-beers-carousel';
import Flash from './flash';
import ImagesCarousel, {ImagesCarouselWithDotsNavigation} from './images-carousel';
import JobsList from './jobs-list';
import MainNav from './main-nav';
import MixpackBeersCarousel from './mixpack-beers-carousel';
import MixpacksCarousel from './mixpacks-carousel';
import OverlayMenu from './overlay-menu';
import PromotionsCarousel from './promotions-carousel';
import RestaurantExclusiveBeersCarousel from './restaurant-exclusive-beers-carousel';
import RestaurantMenuSelector from './restaurant-menu-selector';
import RestaurantMenusCarousel from './restaurant-menus-carousel';
import RestaurantNav from './restaurant-nav';
import RestaurantCarouselSelector from './restaurant-carousel-selector';
import RestaurantSelector from './restaurant-selector';
import RestaurantsCarousel from './restaurants-carousel';
import ScrollToAnchor from './scroll-to-anchor';
import ScrollToSelector from './scroll-to-selector';
import ScrollToRestaurantsCarousel from './scroll-to-restaurants-carousel';
import ShowsCarousel from './shows-carousel';

const ComponentsRecord = {
  AgeValidationDialog,
  BeerBackButton,
  BeerCategoriesCarousel,
  BeersCarousel,
  BeersQuickNav,
  Collapsable,
  Drawer,
  ExclusiveBeersCarousel,
  Flash,
  ImagesCarousel,
  ImagesCarouselWithDotsNavigation,
  JobsList,
  MainNav,
  MixpackBeersCarousel,
  MixpacksCarousel,
  OverlayMenu,
  PromotionsCarousel,
  RestaurantExclusiveBeersCarousel,
  RestaurantMenuSelector,
  RestaurantMenusCarousel,
  RestaurantNav,
  RestaurantCarouselSelector,
  RestaurantSelector,
  RestaurantsCarousel,
  ScrollToAnchor,
  ScrollToSelector,
  ScrollToRestaurantsCarousel,
  ShowsCarousel
};

type ComponentName = keyof typeof ComponentsRecord;

class ComponentsManager {
  private instances: Component[] = [];

  initializeComponents() {
    $('[data-component]').each((_index, element) => {
      const $element = $(element);
      const componentName: ComponentName = $element.data().component;
      const Component = ComponentsRecord[componentName];

      if (!Component) {
        throw Error(`Component ${componentName} not found`);
      }

      const instance = new Component($element);
      instance.initialize();

      this.instances.push(instance);
    });
  }

  destroyComponents() {
    let instance;

    while ((instance = this.instances.shift())) {
      instance.destroy();
    }
  }
}

export default ComponentsManager;
